import React from 'react';
import { Link } from 'gatsby';
import Author from '../author';

interface mainPostProps {
	photo: string;
	abstract: string;
	date: string;
	title: string;
	slug: string;
	authorName: string;
	authorPhoto: string;
}

const MainPost: React.FC<mainPostProps> = ({ photo, abstract, date, title, slug, authorPhoto, authorName }) => {
	return (
		<Link to={`/blogs/${slug}`} className="text-dark">
			<div className="card mb-3 shadow">
				<div className="row g-0">
					<div className="col-md-6">
						<img src={photo} alt="Main Photo" className="img-fluid" />
					</div>
					<div className="col-md-6">
						<div className="card-body">
							<h5 className="card-title">{title}</h5>
							<p className="card-text">{abstract}</p>
							<p className="card-text">
								<small className="text-muted">{date}</small>
							</p>
							<Author name={authorName} photo={authorPhoto} />
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default MainPost;
