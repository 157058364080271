export const MetaData = {
    HOME: {
        META: {
            title: 'Estori | Construye la historia de tu marca en línea.',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico",
            portada: "images/open-graph.png",
        }
    },
    USERS: {
        META: {
            title: 'Estori | Clientes.',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    SHOPS: {
        META: {
            title: 'Estori | Directorio de tiendas.',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    AGENTS: {
        META: {
            title: 'Estori | Red de Agentes.',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    CONDITIONS: {
        META: {
            title: 'Estori | Términos & Condiciones.',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    POLICIES: {
        META: {
            title: 'Estori | Política de privacidad.',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    BLOGS: {
        META: {
            title: 'Estori | Blogs',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    PLANES: {
        META: {
            title: 'Estori | Planes',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    },
    DEMO: {
        META: {
            title: 'Estori | Demo',
            description: 'Reinventa la forma de vender. Crea y administra la tienda online que se ajuste a las necesidades de tu negocio.',
            keywords: 'Estori, Tienda en linea, demo, marcas, ecommerce',
            author: 'Pluriza',
            lang: "es",
            meta: [],
            image: "images/favicon2.ico"
        }
    }
}