import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby';

interface SeoProps {
    title: any
    description: any
    keywords?: any
    author: any
    lang: any
    meta?: any
    image?: any
    portada?: any
}

const Seo: React.FC<SeoProps> = ({title, description, keywords, author, lang, meta, image, portada}) => {

    const { HomePageQuery, ogImageDefault } = useStaticQuery(
		query
    );

    const ogImage = HomePageQuery.siteMetadata.siteUrl.concat(ogImageDefault.childImageSharp.fixed.src);
    
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={title}
                meta={
                    [
                        {
                            name: `description`,
                            content: description,
                        },
                        {
                            name: `keywords`,
                            content: keywords,
                        },
                        {
                            property: `og:title`,
                            content: title,
                        },
                        {
                            property: `og:description`,
                            content: description,
                        },
                        {
                            property: `og:type`,
                            content: `website`,
                        },
                        {
                            property: "og:image",
                            content: "https://estori-assets-prod.s3.amazonaws.com/assets/open-graph.png",
                        },
                        {
                            property: "og:image:secure_url",
                            content: "https://estori-assets-prod.s3.amazonaws.com/assets/open-graph.png",
                        },
                        {
                            property: "og:image:width",
                            content: '1282',
                        },
                        {
                            property: "og:image:height",
                            content: '630',
                        },
                        {
                            property: "og:image:alt",
                            content: 'Lanza tu eCommerce en minutos y posiciona tu marca brindando experiencias de compra personalizadas.',
                        },
                        {
                            name: `twitter:title`,
                            content: title,
                        },
                        {
                            name: `twitter:description`,
                            content: description,
                        },
                        {
                            property: "twitter:image",
                            content:  ogImage,
                        },
                    ].concat(meta)
                }
           />
        </>
    )
};

export default Seo;

export const query = graphql`
query {
    HomePageQuery :
        site {
            siteMetadata {
                title
                description
                author
                siteUrl
            }
        }
    
    ogImageDefault: file(relativePath: { eq: "home/open-graph.png" }) {
        childImageSharp {
            fixed(height: 630, width: 1282) {
                ...GatsbyImageSharpFixed
            }
        }
    }
}
`;
