import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './style.scss';
import Post from '../post';
import MainPost from './MainPost';

const ListBlogs = () => {
	const { allContentfulBlogs: { edges: _blogs } } = useStaticQuery(query);

	const { mainBlog, blogs } = useMemo(
		() => {
			return {
				mainBlog: _blogs[0],
				blogs: [ ..._blogs.slice(1) ]
			};
		},
		[ _blogs ]
	);

	return (
		<section id="blogs" className="blogs-container">
			<div className="container p-0">
				<div className="row">
					<div className="col-md-12">
						<MainPost
							date={mainBlog.node.date}
							title={mainBlog.node.title}
							abstract={mainBlog.node.abstract.abstract}
							photo={mainBlog.node.imageHeader?.fluid?.src}
							slug={mainBlog.node.slug}
							authorName={mainBlog.node.author.name}
							authorPhoto={mainBlog.node.author.photo?.fluid?.src}
						/>
					</div>
				</div>
				<h3>Más Blogs</h3>
				<div className="row row-cols-1 row-cols-md-2 g-4">
					{blogs.map((b) => (
						<Post
							key={b.node.slug}
							date={b.node.date}
							photo={b.node.imageHeader.fluid.src}
							abstract={b.node.abstract.abstract}
							title={b.node.title}
							slug={b.node.slug}
							authorName={b.node.author.name}
							authorPhoto={b.node.author.photo?.fluid?.src}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default ListBlogs;

const query = graphql`
	query {
		allContentfulBlogs(sort: { fields: date, order: DESC }) {
			edges {
				node {
					date(fromNow: true)
					slug
					title
					abstract {
						abstract
					}
					imageHeader {
						fluid {
							src
						}
					}
					author {
						photo {
							fluid {
								src
							}
						}
						name
					}
				}
			}
		}
	}
`;
