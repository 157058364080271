import React from 'react';
import './styles.scss';

interface authorProps {
	name: string;
	photo: string;
}

const Author: React.FC<authorProps> = ({ name, photo }) => {
	return (
		<div className="container">
			<div className="row align-items-center">
				<img src={photo} className="card-thumbail-rounded" />
				<p className="text-muted ml-2">
					<small>{name}</small>
				</p>
			</div>
		</div>
	);
};

export default Author;
