import { Link } from 'gatsby';
import React from 'react';
import Author from '../author';
import './styles.scss';

interface PostProps {
	photo: string;
	abstract: string;
	date: string;
	title: string;
	slug: string;
	authorName: string;
	authorPhoto: string;
}

const Post: React.FC<PostProps> = ({ photo, abstract, date, title, slug, authorName, authorPhoto }) => {
	return (
		<div className="col mb-4">
			<div className="card h-100 shadow">
				{/* <div className="col-lg-12 card-img-content" style={{ padding: '0', margin: '0' }}>
			</div> */}
				<img src={photo} alt="Blog Post" className="card-img-top" />
				<div className="card-body">
					<h4 className="card-title">{title}</h4>
					<p className="card-text">{abstract}</p>
					<Author name={authorName} photo={authorPhoto} />
				</div>
				<div className="card-footer bg-body">
					<div className="row">
						<div className="col-md-6 col-sm-6 col-6">
							<p className="card-text">
								<small className="text-muted">{date}</small>
							</p>
						</div>
						<div className="col-md-6 col-sm-6 col-6">
							<div className="float-right">
								<Link to={`/blogs/${slug}`} className="card-detail">
									Ver mas
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Post;
