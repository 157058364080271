import React from 'react';
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import ListBlogs from '../components/list-blogs';
import SEO from '../components/seo';
import { MetaData } from '../utils/globals';

const Blogs: React.FC<{}> = ({}) => {
    return (
        <>
        <SEO
			title={MetaData.BLOGS.META.title}
			description={MetaData.BLOGS.META.description}
			keywords={MetaData.BLOGS.META.keywords}
			author={MetaData.HOME.META.author}
			lang={MetaData.HOME.META.lang}
			meta={MetaData.HOME.META.meta}
			image={MetaData.HOME.META.image}
			portada={MetaData.HOME.META.portada}
		/>
        <Layout>
            <ListBlogs />
        </Layout>
            <Helmet>
                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js.hs-scripts.com/5117462.js"
                ></script>
           </Helmet>
        </>
    )
}

export default Blogs